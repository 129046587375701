<script setup lang="ts">
import {ComboboxContent, ComboboxRoot, ComboboxTrigger, ComboboxItem, ComboboxItemIndicator} from "radix-vue";

defineProps<{
  timezones: Set<string | undefined>
}>();

const model = defineModel<string>();
</script>

<template>
  <ComboboxRoot v-slot="{open}" v-model="model">
    <ComboboxTrigger :class="$style.timezoneToggle">
      <Icon name="lucide:globe" :class="$style.timezoneToggleIcon"/>
      <TimezoneDropdownItem :timezone="model"/>
      <Icon
          name="lucide:chevron-down"
          :class="[$style.timezoneToggleIcon, { [$style.iconOpen]: open }]"
      />
    </ComboboxTrigger>

    <ComboboxContent :class="$style.timezoneDropdown">
      <ComboboxItem v-for="timezone in timezones" :key="timezone" :value="timezone" :class="$style.timezoneComboboxItem">
        <TimezoneDropdownItem :timezone="timezone"/>
        <ComboboxItemIndicator>
          <Icon name="lucide:check" :class="$style.timezoneItemIcon"/>
        </ComboboxItemIndicator>
      </ComboboxItem>
    </ComboboxContent>
  </ComboboxRoot>
</template>

<style module>
.timezoneToggle {
  display: flex;
  align-items: center;
  gap: var(--tw-size-2);
  color: var(--Text__Secondary);
  background: var(--Fon__Main);
  padding: 5px 12px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.timezoneToggleIcon {
  color: var(--Icon__Secondary);
}

.iconOpen {
  transform: rotate(180deg);
}

.timezoneDropdown {
  background: var(--Fon__Card);
  border-radius: 8px;
  padding: var(--tw-size-2);
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-block-start: var(--tw-size-1);
  box-shadow: var(--main-shadow-m);
  border: 1px solid var(--border-color);
}

.timezoneComboboxItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--tw-size-2);
  padding: 8px 12px;
  border-radius: 8px;
}

.timezoneComboboxItem[data-highlighted] {
  background: var(--Fon__Main);
}

.timezoneItemIcon {
  color: var(--Icon__Success);
}
</style>
