<script setup lang="ts">
import {useTimezone} from "@core/composables/useTimezone";

const {data: timezones} = await usePaymentApi<string[]>('/utils/available_timezones');

const timezonesSet = computed(() => {
  if (!timezones.value) {
    return new Set(['Etc/UTC', undefined]);
  }

  const aliasMap = {
    'Europe/Kiev': 'Europe/Kyiv',
    'America/New_York': 'US/Eastern',
    'America/Chicago': 'US/Central',
    'America/Denver': 'US/Mountain',
    'America/Los_Angeles': 'US/Pacific',
    'Pacific/Auckland': 'NZ',
    'Europe/London': 'GB',
  } as const;

  const set = new Set(['Etc/UTC', undefined]);

  timezones.value.forEach(el => {
    const canonicalTimezone = aliasMap[el as keyof typeof aliasMap] || el;
    set.add(canonicalTimezone);
  });

  return set;
});

const timezoneState = useTimezone();

watch(timezoneState, (newValue) => {
  if (import.meta.client) {
    localStorage.setItem('SG_preferred_timezone', newValue);
  }
});

callOnce(() => {
  if (import.meta.client) {
    let localSavedTimezone = localStorage.getItem('SG_preferred_timezone');

    if (localSavedTimezone !== null) {
      localSavedTimezone = localSavedTimezone === "undefined" ? undefined : localSavedTimezone;

      if (timezonesSet.value.has(localSavedTimezone)) {
        timezoneState.value = localSavedTimezone;
      }

      localStorage.removeItem('SG_preferred_timezone');
    }
  }
});
</script>

<template>
  <TimezoneDropdown v-model="timezoneState" :timezones="timezonesSet"/>
</template>

<style scoped>

</style>