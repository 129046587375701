<script setup lang="ts">
const props = defineProps<{
  timezone: string | undefined
}>();

const intlObject = computed(() => {
  return new Intl.DateTimeFormat(undefined, {
    timeZone: props.timezone,
  });
});

const currentDate = computed(() => {
  const date = new Date();

  return intlObject.value.format(date);
});
</script>

<template>
  <div :class="$style.timezoneItem">
    <dl :class="$style.timezoneItemList">
      <dt :class="$style.timezoneItemDate">{{ currentDate }}</dt>
      <dd :class="$style.timezoneItemTimezone">{{ formatTimezone($props.timezone) }}</dd>
    </dl>
    <Icon v-if="$props.timezone === undefined" name="lucide:house"/>
  </div>
</template>

<style module>
.timezoneItem {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--Text__Secondary);
}

.timezoneItemList {
  text-align: left;
}

.timezoneItemDate {
  color: var(--Text__Main);
  font-size: 0.75rem;
  line-height: 1.25;
}

.timezoneItemTimezone {
  font-size: 0.625rem;
  line-height: 1.2;
  text-wrap: nowrap;
}
</style>